@font-face {
  font-family: 'cirruscumulusregular';
  src: url('../assets/fonts/CirrusCumulus.woff2') format('woff2'), url('../assets/fonts/CirrusCumulus.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'cirruscumulusregular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h2 {
  font-size: 45px;
  margin: 0;

  @media screen and (min-width: 400px) {
    font-size: 50px;
  }

  @media screen and (min-width: 500px) {
    font-size: 55px;
  }

  @media screen and (min-width: 600px) {
    font-size: 60px;
  }

  @media screen and (min-width: 700px) {
    font-size: 65px;
  }

  @media screen and (min-width: 800px) {
    font-size: 70px;
  }

  @media screen and (min-width: 900px) {
    font-size: 75px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 80px;
  }

  @media screen and (min-width: 1100px) {
    font-size: 85px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 90px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 95px;
  }
}

p {
  font-size: 5vw;

  @media screen and (min-width: 700px) {
    font-size: 4vw;
  }

  @media screen and (min-width: 1000px) {
    font-size: 3.5vw;
  }

  @media screen and (min-width: 1200px) {
    font-size: 3vw;
  }
}